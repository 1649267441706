const graphQLAllHeadersAndFooters = `
  allFile(
    filter: { extension: { eq: "svg" }, name: { regex: "/flag/" } }
  ) {
    edges {
      node {
        name
        publicURL
      }
    }
  }
  prismic{

    allHeaders {
      edges {
        node {
          alert_message
          header_call_to_action {
            header_call_to_action_id
            header_call_to_action_name
            header_call_to_action_link {
              ... on PRISMIC__Document {
                _meta {
                  uid
                }
              }
            }
          }
          header_social_title
          header_social {
            header_social_id
            header_social_link {
              ... on PRISMIC__ExternalLink {
                url
              }
            }
          }
          header_logo_image
          _meta {
            lang
          }
          body{
            __typename
            ... on PRISMIC_HeaderBodyMenu_element{
              type
              label
              primary{
                is_hidden
                menu_element_id
                menu_element_name
                menu_element_link{
                  _linkType
                  ... on PRISMIC__Document {
                    _meta {
                      uid
                    }
                  }
                  ... on PRISMIC__ExternalLink {
                    url
                  }
                }
              }
            }
            ... on PRISMIC_HeaderBodyMenu_element_with_subelements{
              type
              primary{
                is_hidden
                menu_group_id
                menu_group_name
              }
              fields{
                menu_group_element_id
                menu_group_element_name
                menu_group_element_link{
                  _linkType
                  ... on PRISMIC__Document {
                    _meta {
                      uid
                    }
                  }
                  ... on PRISMIC__ExternalLink {
                    url
                  }
                }
              }
            }
            ... on PRISMIC_HeaderBodyMenu_group_with_subgroup {
              type
              primary {
                menu_group_with_sub_group_master_title
                menu_group_with_sub_group_id
                menu_group_with_sub_group_name
                is_hidden
              }
              fields {
                menu_group_with_sub_group_element_id
                menu_group_with_sub_group_element_name
                menu_groupe_with_sub_group_element_filter
                menu_group_with_sub_group_element_link{
                  ... on PRISMIC__Document{
                    _meta{
                      uid
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    
    allFooters {
      edges {
        node {
          _meta {
            uid
            lang
          }
          copyright
          last_update
          column_title
          link_app_1{
            ... on PRISMIC__ExternalLink {
              url
            }
          }
          image_app_1
          link_app_2{
            ... on PRISMIC__ExternalLink {
              url
            }
          }
          image_app_2
          body {
            ... on PRISMIC_FooterBodyCategory {
              primary {
                category_id
                category_name
              }
              fields {
                footer_element_id
                footer_element_name
                footer_element_link {
                  _linkType
                  ... on PRISMIC__Document {
                    _meta {
                      uid
                    }
                  }
                  ... on PRISMIC__ExternalLink {
                    url
                    target
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  currentBuildDate {
    currentDate
  }
`
module.exports = graphQLAllHeadersAndFooters